import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PlayerRewardStatusParam } from '@/utils/query/params';

export const queryParams = {
  id: StringParam,
  updatedAtFrom: DateTimeParam,
  updatedAtTo: DateTimeParam,
  rewardStatus: PlayerRewardStatusParam,
  desc: withDefault(BooleanParam, true),
  before: StringParam,
  after: StringParam,
};
